.Section {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  overflow: hidden;

  &:not(.Section--app):last-child {
    padding-bottom: 8.5rem;
  }
}

.Section--viewImage {
  display: flex;
  padding: var(--gutter);
  padding-top: 12vh;
  min-height: var(--inner-height);
  height: 100%;
  background-color: var(--color-base);
  align-items: center;

  &.isCropper {
    //padding-top: 0;
  }
}

.Section--viewImageImageWrapper {
  position: absolute;
  left: 50%;
  //Top set by js
  transform: translateX(-50%);
  max-height: 72vh;
  width: calc(100% - var(--gutter) * 2);
  max-width: 50vh;
  overflow: hidden;

  &.--is-landscape {
    max-height: 72vh;
    width: calc(100% - var(--gutter) * 2);
    max-width: 120vh;
    margin-bottom: 25%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.Section--app {
  min-height: calc(var(--inner-height));
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.Section-background {
  position: absolute;
  inset: 0;
  background-color: black;

  .Upload & {
    background-image: url("../../public/example-photos/example-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.75;
  }
}

.Section-backgroundImage {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.85;
}

.Section-shade {
  position: fixed;
  height: var(--inner-height);
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.Section-backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Section-content {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.Section-content--centered {
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.Section-blur {
  backdrop-filter: blur(30px);
  width: 100%;
  height: 100%;
}

.Section-blocks {
  > * {
    display: flex;
    flex: 1;

    > * {
      @media screen and (min-width: $mq-lg) {
        width: 50%;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
}

.Section-introWrap {
  display: flex;
  flex-flow: column;

  @media screen and (min-width: $mq-lg) {
    flex-flow: row;
  }
}

@media screen and (max-width: $mq-sm) {
  .Section {
    &:not(.Section--app):last-child {
      padding-bottom: 7.2rem;
    }
  }
}
