.SingleImage {
  display: flex;
  flex-direction: column;
  transition: 1s opacity;

  &.--is-hidden {
    opacity: 0;
  }
}

.SingleImage-imageAndArrowWrapper {
  display: flex;
  flex-direction: column;
  margin-top: var(--nav-height);
  max-height: calc(100vh - var(--nav-height));
  padding-left: var(--gutter);
  padding-right: var(--gutter);

  .--is-portrait & {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gutter);
    max-height: calc(100vh - var(--nav-height) - var(--gutter));
  }
}

.SingleImage-imageWrapper {
  position: relative;
  margin: 0;
  max-height: calc(100vh - var(--nav-height) - 10rem);
  img {
    height: 100%;
    max-height: calc(100vh - var(--nav-height) - 10rem);
    object-fit: cover;
  }
  .--is-portrait & {
    max-height: calc(100vh - var(--nav-height) - var(--gutter));
    img {
      max-height: calc(100vh - var(--nav-height) - var(--gutter));
    }
  }
}

.SingleImage-shareButtonsWrapper {
  position: absolute;
  bottom: var(--gutter);
  left: 50%;
  display: flex;
  gap: var(--gutter);
  transform: translateX(-50%);
  margin-bottom: var(--gutter);

  .u-iconButton {
    display: none;
  }
}

//Voila, Big arrow
.SingleImage-topWrapper {
  width: 100%;
  height: 10rem;
  h2 {
    @include font-h1;
    color: var(--color-yellow);
  }

  .--is-portrait & {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    h2 {
      transform: translateY(2rem);
    }
  }
}

.SingleImage-topWrapperContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  .--is-portrait & {
    margin-left: auto;
    height: auto;
    width: auto;
    flex-direction: column;
    align-items: flex-start;

    &.--small-header {
      h2 {
        transform: translateY(3rem);
      }
    }
  }

  &.--small-header {
    h2 {
      font-size: 5.5rem;
      white-space: pre;
    }
  }
}

.SingleImage-graphicsContentWrapper {
  padding: var(--gutter);
  &.--is-landscape {
    padding-top: 0;
  }
}

.SingleImage-treeWrapperBlock {
  order: 2;
}

.SingleImage-infoBlocksWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gutter);
}

.SingleImage-infoTextBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-yellow);
  height: 20rem;
  grid-column: span 2;
  padding: calc(var(--gutter) * 3);
  gap: var(--gutter);
  h3 {
    @include font-h3;
    font-size: 3vw;
    line-height: 3.5vw;
    color: white;
    max-width: 100%;
  }
  p {
    color: white;
  }
}

.SingleImage-inlineArrow {
  display: inline-block;
  transform-origin: 0;
  transform: scale(2);
  width: 1rem;
  height: 1rem;
  background-image: url("../img/arrow-small.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  &.--yellow {
    background-image: url("../img/arrow-small-yellow.svg");
  }
}

.SingleImage-graphicWrapperBlock {
  display: flex;
  gap: var(--gutter);
  grid-column: span 1;
  height: 20rem;
}

.SingleImage-imageBlock {
  grid-column: span 2;
  aspect-ratio: 16/9;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.SingleImage-bottomTextBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    @include font-h1;
    color: var(--color-yellow);
    margin-bottom: 1rem;
  }
  p {
    color: var(--color-yellow);
  }
  span {
  }
}

@media screen and (max-width: $mq-lg) {
  .SingleImage-shareButtonsWrapper {
    margin-bottom: 0;
  }
  .SingleImage-infoBlocksWrapper {
    grid-template-columns: 1fr 1fr;
  }
  .SingleImage-infoTextBlock {
    grid-area: span 1;
    padding: calc(var(--gutter) * 2);
    padding-right: var(--gutter);
    h3 {
      max-width: 100%;
    }
  }

  .SingleImage-shareButtonsWrapper {
    left: var(--gutter);
    top: var(--gutter);
    bottom: auto;
    transform: translateX(0);

    .u-button {
      display: none;
    }

    .u-iconButton {
      display: flex;
    }
  }
}

@media screen and (max-width: $mq-sm) {
  .SingleImage-imageAndArrowWrapper {
    max-height: 100%;
    gap: 0;
    .--is-portrait & {
      gap: 0;

      display: flex;
      flex-direction: column;
      max-height: 100%;
    }
  }

  .SingleImage-topWrapper {
    height: auto;
  }

  .SingleImage-topWrapperContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateY(-0.8rem);

    h2 {
      transform: translateY(2rem);
    }

    .--is-portrait & {
      margin-left: 0;
      margin-right: auto;
      &.--small-header {
        transform: translateY(-0.6rem);
        h2 {
          transform: translateY(0.8rem);
          height: 4rem;
        }
      }
    }

    &.--small-header {
      h2 {
        font-size: 4.6rem;
      }
    }
  }

  .SingleImage-graphicsContentWrapper {
    padding-top: 0;
  }

  .SingleImage-graphicWrapperBlock {
    height: clamp(16rem, 60vw, 30rem);
  }

  .SingleImage-treeWrapperBlock {
    order: 4;
  }

  .SingleImage-infoBlocksWrapper {
    display: flex;
    flex-direction: column;
  }

  .SingleImage-infoTextBlock {
    padding: var(--gutter);
    height: 15rem;
    h3 {
      @include font-h3;
      color: var(--color-base);
      max-width: 100%;
    }
    p {
      max-width: 80%;
    }

    p span {
      margin-left: var(--gutter-tiny);
      transform-origin: 0 30%;
      transform: scale(1.5);
    }
  }
}

@media screen and (min-width: $mq-xl-desktop) {
  .SingleImage {
  }

  .SingleImage-infoTextBlock {
    height: 26rem;
  }

  .SingleImage-graphicWrapperBlock {
    height: 26rem;
  }
}
