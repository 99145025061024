.Admin {
  padding: var(--gutter) var(--gutter-large);
  padding-top: 8rem;
  display: flex;
  flex-flow: column;
  align-items: center;

  h1 {
    margin: var(--gutter-large) 0;
    font-size: var(--font-size-large);
    line-height: var(--line-height-large);
  }

  h2 {
    margin: var(--gutter) 0;
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
  }
}

.Admin-nav {
  display: flex;
  align-items: flex-end;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  right: 0;
  padding: var(--gutter);
  background-color: var(--color-base);
  box-shadow: 0 -20px 25px rgba(0, 0, 0, 0.1);

  &.is-hidden {
    display: none;
  }
}

.Admin-navInfo {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  margin-right: auto;

  p {
    margin: 0;
  }
}

.Admin-navActions {
  display: flex;
  gap: var(--gutter);
  margin-left: auto;
}

.Admin-loginWrap {
  height: calc(100vh - var(--nav-height));
  transform: translateY(calc(var(--gutter) * -1));
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;

  h2 {
    text-align: center;
  }
}

.Admin-offsetButtonsWrapper {
  display: flex;
  justify-content: center;
  gap: var(--gutter);
  padding: 2rem;
  width: 15rem;
  margin-left: auto;
  margin-right: auto;
}

.Admin-loginErrorText {
  color: red;
  margin: 0;
  padding: 0;
}

.Admin-userInfo {
  margin-left: auto;
}

.Admin-images {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: var(--gutter);
  margin-bottom: 2rem;

  @media screen and (max-width: $mq-lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: $mq-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: $mq-xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.Admin-images--featured {
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: var(--gutter-large);
  padding-bottom: 0;
}

.Admin-imageControls {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.Admin-imageWrap {
  display: flex;
  flex-flow: column;
}

.Admin-image {
  object-fit: cover;
  object-position: center;
  cursor: zoom-in;
  flex: 1;
}

@media screen and (max-width: $mq-sm) {
  .Admin {
    padding: var(--gutter);
    padding-top: 9rem;
  }

  .Admin-nav {
    flex-direction: column;
    margin-right: 0;
    align-items: flex-start;
    gap: var(--gutter);
  }
  .Admin-navActions {
    margin-left: 0;
  }

  .Admin-images--featured {
    grid-template-columns: repeat(2, 1fr);
  }
}
