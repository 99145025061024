.Footer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gutter);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  width: 100%;
  font-size: var(--font-size-extra-small);
  line-height: var(--line-height-small);
  position: relative;
  background-color: var(--color-green);

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: calc(var(--gutter) * 1 + 1px);
    background-color: var(--color-footer);
  }

  @media screen and (max-width: $mq-sm) {
    flex-flow: column;
  }

  > * {
    padding: calc(var(--gutter) * 1.5) var(--gutter);
  }
}

.Footer-logoLink {
  display: block;
  display: none;
  width: 5rem;
  margin-bottom: var(--line-height-regular);

  img {
    width: 100%;
  }
}

.Footer-main {
  background-color: var(--color-footer);
  grid-column: span 2;

  flex: 1;

  > * {
    @media screen and (min-width: $mq-lg) {
      margin-left: 16vw;
    }
  }
}

.Footer-text {
  margin-bottom: var(--line-height-small);
}

.Footer-privacy {
  display: flex;
  flex-flow: column;
  background-color: var(--color-green);
  color: var(--color-base);

  @media screen and (max-width: $mq-xs) {
    padding: var(--gutter-large) var(--gutter);
  }

  @media screen and (min-width: $mq-md) {
    min-width: 30%;
  }
}

.Footer-socialMedia {
  display: flex;
  gap: 0.5rem;

  @media screen and (min-width: $mq-lg) {
    margin-left: 16vw;
  }
}

.Footer-socialMediaLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  font-weight: 700;
  border-radius: 50%;
  background-color: var(--color-base);

  svg {
    width: 1rem;
    height: 1rem;
  }

  &#twitter svg {
    transform: translateY(2px);
  }

  &#youtube svg {
    transform: translateY(2px);
  }

  &#facebook svg {
    transform: translateX(-1px);
  }

  &#pinterest svg {
    width: 1rem;
    height: 1rem;
  }
}

.Footer-privacyLink {
  font-weight: 700;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.5em;

  &:not(:last-child) {
    margin-bottom: var(--line-height-small);
  }
}

@media screen and (max-width: $mq-sm) {
  .Footer {
    grid-template-columns: 1fr;
    padding-right: 0;
    padding-left: 0;
    &::after {
      display: none;
    }
  }
}
