.Layout {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  height: 100%;
  max-height: 100vh;
}

.Layout-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  padding: var(--gutter-large);
}

.Layout-viewTitle {
  font-size: var(--font-size-medium);
  font-weight: 700;
}

.Layout-title {
  font-size: var(--font-size-medium);
  margin-bottom: var(--gutter);
  white-space: nowrap;
}

.Layout-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: var(--gutter);
}

.Layout-top {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: var(--gutter);
  margin: var(--gutter-large) auto var(--gutter-large) var(--gutter-large);
}

.Layout-bottom {
  margin-top: auto;
  width: 100%;
}

.Layout-goBack {
  font-weight: 500;
}

.Layout-imageWrap {
  height: 50vh;
  min-height: 32rem;
  border: 2px solid var(--color-contrast);
  width: 100%;
  max-width: 32rem;
  margin-bottom: var(--gutter-large);
  border-radius: var(--border-radius);
}

.Layout-actionsWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Layout-fileUpload {
  opacity: 0;
  position: absolute;
  inset: 0;
  cursor: pointer;
}

.Layout-buttonIcon {
  font-size: 2rem;
  line-height: 1em;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

.Layout-uploadedImage {
  flex: 1;
  border: 1px solid var(--color-contrast);
  overflow: hidden;
  width: 100%;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.Layout-uploadedImage--reference {
  position: absolute;
  right: var(--gutter-large);
  top: var(--gutter-large);
  max-width: 25vw;
}

.Layout-error {
  margin-top: var(--gutter);
  color: red;
  font-weight: 700;
}

.Layout-savedImage {
  position: relative;
}

.Layout-imageReference {
  max-width: 25vw;
  min-width: 12rem;
  position: absolute;
  right: -10%;
  top: 0;
}
