.Dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  margin: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: var(--gutter);
  background-color: var(--color-base);
  color: var(--color-contrast);
  z-index: 9999999999;

  // initial visibility
  display: none;

  p {
    text-align: center;
  }

  &.is-visible {
    display: flex;
  }

  .Button {
    margin-bottom: var(--gutter);
  }
}

.Dialog-overlay {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  z-index: 999999999;

  &.is-visible {
    opacity: 1;
    pointer-events: initial;
  }
}
