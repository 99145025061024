//New
.Feed {
  background-color: white;
}

.Feed-grid {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr 1fr;
  margin-top: var(--nav-height);
  gap: var(--gutter);
  padding: var(--gutter);
  padding-top: 0;
}

.Feed-grid--start {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--gutter-tiny);
  figure:nth-of-type(3n + 2) {
    transform: translateY(-4rem);
  }
}

.Feed-clickableHouse {
  position: relative;
  img {
    opacity: 0;
    &.--loaded {
      //transition: 1s opacity;
      opacity: 1;
    }
  }
}

.Feed-clickableHouseNavButton {
  position: absolute;
  width: 100%;
  height: 100%;
}

.Feed-imageWrapper {
  border-radius: var(--border-radius);
  overflow: hidden;
}

.Feed-feedToggleWrapper {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: flex;
  justify-content: space-between;
  color: white;
  height: 9.2vh;
  width: auto;
  gap: var(--gutter);
  z-index: 999;

  span {
    display: flex;
    align-items: center;
    font-size: 7.1vw;
    color: black;
    font-weight: 600;
  }
}

@media screen and (max-width: $mq-lg) {
  .Feed-feedToggleWrapper {
    top: calc(var(--nav-height) + var(--gutter));
    bottom: auto;
  }
}

@media screen and (max-width: $mq-lg) {
  .Feed-grid {
    grid-template-columns: 1fr;
    column-gap: 0;
  }
}
