.Alert {
  position: fixed;
  right: var(--gutter);
  top: var(--gutter);
  padding: 0.5rem 1rem;
  font-size: var(--font-size-extra-small);
  color: var(--color-base);
  background-color: var(--color-base);
  z-index: 999999999;
  border-radius: var(--border-radius);
  font-weight: 700;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);

  &.--error {
    border-color: var(--color-red);
  }

  &.--success {
    background-color: var(--color-blue);
  }
}
