.WoodModuleAnimation {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 12rem;
  width: 18rem;

  figure {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
