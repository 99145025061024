.Generation {
  height: calc(var(--inner-height) - var(--nav-height));
}

.Generation-generatedImage {
  width: 100%;

  @media screen and (min-width: $mq-lg) {
    height: 100%;
    margin: auto;

    img {
      height: 100%;
      width: auto;
      margin: auto;
    }
  }
}

.Generation-buttonWrap {
  position: absolute;
  top: 3vh;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  padding: 0 var(--gutter-large);

  button {
    padding: 1rem 1.5rem;
  }
}

.Generation-nsfwWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gutter);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h2 {
    @include font-body;
    color: var(--color-yellow);
  }
  a {
    line-height: 0;
    span {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      background-image: url("../img/arrow-small-yellow.svg");
      transform: rotate(180deg) translateY(-0.3rem);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }
}
