.Upload {
}

.Upload-uploadArea {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  border-radius: var(--border-radius);
  max-height: 100%;
  background-color: var(--color-base);
  color: var(--color-contrast);
}

.Upload-addImageicon {
  width: 6rem;
}
