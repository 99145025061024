.MainMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  grid-template-rows: repeat(4, 1fr);
  padding: 0.15rem 0 0 0.15rem;
  gap: 0.15rem;
  top: calc(var(--nav-height));
  right: 0;
  width: 50vw;
  height: calc(100vh - var(--nav-height));
  background-color: var(--color-yellow);
  transform: translateX(100%);
  transition: 0.3s transform;
  body.--menu-is-open & {
    transform: translateX(0);
  }
}

.MainMenu-closeArea {
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;

  body.--menu-is-open & {
    width: 100vw;
    height: 100vh;
  }
}

.MainMenu-gridItem {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: white;
  padding: var(--gutter-large);
  height: calc((100% - var(--gutter-tiny)) / 4);

  button {
    width: 100%;
    height: 100%;
    text-align: left;
    transform: translateX(6rem);
    transition: 0.45s transform;
    body.--menu-is-open & {
      transform: translateX(0);
    }

    transition: 0.2s opacity;
    &.--blured {
      opacity: 0.5;
    }
    span {
      @include font-h1;
      font-size: clamp(2rem, 12vh, var(--font-size-very-large));
      color: var(--color-yellow);
      line-height: 0.7;
    }
  }
}

.MainMenu-gridItem--textArea {
  height: calc((100% - var(--gutter-tiny)) / 2);
  padding: var(--gutter-large);
  align-items: start;
  font-weight: 300;
  overflow-y: scroll;
  a {
    color: black;
    font-size: clamp(0.2rem, 2.5vh, var(--font-size-regular));
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: $mq-lg) {
  .MainMenu {
    width: 100vw;
  }
  .MainMenu-gridItem {
    padding: var(--gutter);
    font-weight: 400;
    height: calc((100% - var(--gutter-tiny)) / 5);
  }

  .MainMenu-gridItem--textArea {
    height: calc((100% - var(--gutter-tiny)) / 1.666);
  }
}
