.LoaderScreen {
  width: 100%;
  height: var(--inner-height);
}

.LoaderScreen-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gutter);

  position: relative;
  background-color: var(--color-base);
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  padding: var(--gutter);
  font-weight: 700;

  h2 {
    @include font-body;
    margin-top: 2rem;
    color: var(--color-yellow);
  }
}

@media screen and (max-width: $mq-lg) {
  .LoaderScreen-content {
    h2 {
      @include font-h3;
      margin-top: 2rem;
      color: var(--color-yellow);
      text-align: center;
    }
  }
}
