.ShareModal {
  position: fixed;
  display: flex;
  gap: var(--gutter);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
}

.ShareModal-closeArea {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.5);
}

.ShareModal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 3rem;
  height: 3rem;
  border-radius: 1000px;
}

.ShareModal-copiedBox {
  position: fixed;
  top: calc(50% + var(--gutter) * 3);
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(128, 128, 128, 0.5);
  opacity: 0;
  transition: 0.5s opacity;
  padding: 0.5rem 1rem;
  z-index: 999999999;
  border-radius: 1000px;

  p {
    @include font-small;
    padding-top: 0.25rem;
    margin: 0;
    color: var(--color-base);
    opacity: 0.8;
    white-space: pre;
  }
}

@media screen and (max-width: $mq-lg) {
  .ShareModal-copiedBox {
    top: calc(50% + var(--gutter) * 4);
  }
}
