.Lightbox {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: var(--gutter-large);
  transition: opacity 0.3s;

  &:not(.is-visible) {
    opacity: 0;
    pointer-events: none;
  }
}

.Lightbox-closeArea {
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  inset: 0;
  z-index: 50;
}

.Lightbox-imagesWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.Lightbox-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 500;
  left: var(--gutter);
  right: var(--gutter);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Lightbox-button {
  padding-top: 0.2rem;
  width: 3rem;
  height: 3rem;
  background-color: var(--color-base);
  border-radius: 999px;
}

.Lightbox-button--close {
  position: absolute;
  top: var(--gutter);
  right: var(--gutter);
  width: 2rem;
  height: 2rem;
  z-index: 500;
  padding: 0;
}

.Lightbox-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: auto;
  max-width: 92vw;
  object-fit: contain;
  z-index: 100;

  &:not(.is-active) {
    opacity: 0;
    pointer-events: none;
  }
}
