:root {
  /* Widths and weights */
  --base-size: clamp(14px, 1vw, 18px);
  --max-width: 1160px;
  --gutter-tiny: 0.25rem;
  --gutter: clamp(1rem, 2vw, 1.5rem);
  --gutter-large: 3rem;
  --gutter-huge: 6rem;
  --menu-icon-size: 24px;
  --menu-icon-thickness: 6px;
  --border-radius: 0.5rem;
  --nav-height: 6.5rem;

  /* Fonts */
  --font-family--primary: HelveticaNeue, -apple-system, BlinkMacSystemFont,
    Arial, sans-serif;

  /* Font sizes */
  --font-size-extra-small: 0.875rem;
  --line-height-extra-small: 1.2rem;
  --font-size-small: 1.2rem;
  --line-height-small: 1.4rem;
  --font-size-nav: 1.25rem;
  --line-height-nav: 1.625rem;
  --font-size-regular: 1.4rem;
  --line-height-regular: 1.55rem;
  --font-size-medium: 3rem;
  --line-height-medium: 3.5rem;
  --font-size-large: 3.75rem;
  --line-height-large: 3.5rem;
  --font-size-very-large: 7.5rem;
  --line-height-very-large: 7rem;
  /* Colors */

  --color-base: #fff;
  --color-contrast: #000;
  --color-footer: #edeff5;
  --color-background: #d3d7da;
  --color-gray: #888b8d;
  --color-mid-gray: #bbbbbb;
  --color-light-gray: #e4e4e4;
  --color-blue: #009fdf;
  --color-green: #78be20;
  --color-light-green: #9cc45c;
  --color-light-yellow: #fbf3bb;
  --color-yellow: #ffd100;
  --color-orange: #fb8c05;
  --color-error: #ff4229;

  /* Transitions */
  --transition-duration--short: 0.25s;
  --transition-duration: 0.4s;
  --transition-timing-function: cubic-bezier(0.3, 0.7, 0.55, 0.9);
}

$mq-xs: 365px;
$mq-sm: 640px;
$mq-md: 768px;
$mq-lg: 1024px;
$mq-laptop: 1440px;
$mq-desktop: 1680px;
$mq-xl-desktop: 2000px;

@media screen and (max-width: $mq-lg) {
  :root {
    --font-size-very-large: clamp(5.5rem, 12vw, 7.5rem);
    --line-height-very-large: clamp(6rem, 12vw, 7rem);
  }
}

@media screen and (max-width: $mq-sm) {
  :root {
    --font-size-regular: 1.1rem;
    --line-height-regular: 1.33rem;
    --font-size-medium: 1.7rem;
    --line-height-medium: 2rem;
    --font-size-very-large: 5.5rem;
    --line-height-very-large: 6rem;
  }
}
