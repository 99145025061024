@import "_vars";
@import "_reset";
@import "_fonts";
@import "_mixins";
@import "_utils";
@import "Layout.scss";
@import "Navigation.scss";
@import "Section.scss";
@import "Generation.scss";
@import "Upload.scss";
@import "Block.scss";
@import "Button.scss";
@import "Button.scss";
@import "Footer.scss";
@import "Feed.scss";
@import "LoaderSpinner.scss";
@import "LoaderScreen.scss";
@import "Alert.scss";
@import "Form.scss";
@import "Admin.scss";
@import "Lightbox.scss";
@import "Dialog.scss";
@import "Carousel.scss";
@import "ImageCropper.scss";
@import "ApproveBox.scss";
@import "MainMenu.scss";
@import "SingleImage.scss";
@import "AnimatedGraphic.scss";
@import "WoodModuleAnimation.scss";
@import "Modal.scss";
@import "ShareModal.scss";

html {
  font-size: var(--base-size);
  height: 100%;
}

body {
  @include font-body;
  background-color: var(--color-base);
  color: var(--color-contrast);
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;

  > * {
    pointer-events: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

p {
  margin-bottom: var(--line-height-regular);

  &:last-child {
    margin-bottom: 0;
  }

  a {
    font-weight: 700;
    white-space: nowrap;
  }
}

img {
  display: block;
  height: auto;
  width: 100%;
}

@media screen and (max-width: $mq-lg) {
  body {
    font-weight: 400;
  }
}

/* End Base */
