.ApproveBox {
  display: flex;
  background-color: white;
  padding: calc(var(--gutter) * 0.5);
  width: fit-content;
  margin: auto;
  gap: var(--gutter);
  border-radius: 0.5rem;
  .ApproveBox-button {
    width: 3rem;
    height: 3rem;
    border: 1px solid black;
    border-radius: 0.5rem;
    &.approved {
      background-color: var(--color-blue);
      border-color: var(--color-blue);
    }
  }
}
