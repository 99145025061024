@font-face {
  font-family: HelveticaNeue;
  font-weight: 300;
  src: url("../../public/fonts/HelveticaNeueLTStd-Lt.woff2");
}

@font-face {
  font-family: HelveticaNeue;
  font-weight: 400;
  src: url("../../public/fonts/HelveticaNeueLTW06-55Roman.woff");
}

@font-face {
  font-family: HelveticaNeue;
  font-weight: 700;
  src: url("../../public/fonts/HelveticaNeueLTW06-75Bold.woff");
}
