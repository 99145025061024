.AnimatedGraphic-infoBlocksWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gutter);
}

.AnimatedGraphic-graphicWrapperBlock {
  display: flex;
  gap: var(--gutter);
  grid-column: span 1;
  height: 20rem;
}

.AnimatedGraphic-animationWrapper {
  display: flex;
  gap: var(--gutter);
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.AnimatedGraphic-animationPart {
  position: absolute;
  width: 100%;
  height: 100%;
  animation-fill-mode: forwards;
}

.AnimatedGraphic-housePart--gable {
  background-color: var(--color-yellow);
  clip-path: polygon(0 40%, 25% 0, 50% 40%, 50% 100%, 0 100%);
  transform: translateX(-50%);

  .--animate & {
    animation-name: gableAnimation;
    animation-duration: 0.75s;
  }
}

.AnimatedGraphic-housePart--side {
  background-color: var(--color-green);
  clip-path: polygon(50% 40%, 100% 40%, 100% 100%, 50% 100%);
  transform: translateY(61%);

  .--animate & {
    animation-name: sideAnimation;
    animation-duration: 0.75s;
    animation-delay: 0.25s;
  }
}

.AnimatedGraphic-housePart--roof {
  background-color: var(--color-light-green);
  clip-path: polygon(25% 0, 75% 0, 100% 40%, 50% 40%);
  transform: translateY(-51%);

  .--animate & {
    animation-name: roofAnimation;
    animation-duration: 0.75s;
    animation-delay: 0.5s;
  }
}

@keyframes gableAnimation {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes sideAnimation {
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes roofAnimation {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}

.AnimatedGraphic-singleTree {
  position: relative;
  width: 50%;
  height: 100%;
  transform: scale(0);
  transform-origin: 50% 100%;

  &.--right {
    right: 0;
  }

  .--animate & {
    animation-name: treeAnimation;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
  }

  &.--second {
    animation-delay: 0.25s;
  }
}

.AnimatedGraphic-treePart--trunk {
  left: 35%;
  bottom: 0;
  right: 0;
  height: 75%;
  width: 30%;
  background-color: var(--color-yellow);
  border-top-right-radius: 1000rem;
  border-top-left-radius: 1000rem;
}

.AnimatedGraphic-treePart--crown {
  height: auto;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: var(--color-green);
}

@keyframes treeAnimation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
