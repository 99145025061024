.Modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999999;
  overflow: hidden;
}

.Modal-contentWrapper {
  position: fixed;
  left: 50%;
  top: clamp(8rem, 14vh, 12rem);
  transform: translateX(-50%);
  width: 80vw;
  height: 68vh;
  max-width: 40rem;
  overflow-y: scroll;
  //border-radius: 1.5rem;
  border: 2px solid var(--color-yellow);

  background-color: white;
  padding: calc(var(--gutter) * 2);
  white-space: normal;
  text-align: left;
  z-index: 99999999;
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

  h2 {
    @include font-body;
    font-weight: 700;
    margin-bottom: var(--gutter);
  }

  p {
    @include font-size-extra-small;
  }
}

.Modal-link {
  text-decoration: underline;
  font-weight: 300;
  span {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-image: url("../img/external-link.svg");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transform: translate(-0.2em, 0.2em);
  }
}

.Modal-closeButton {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: calc(85vh + var(--gutter));
  z-index: 99999999;
}

@media screen and (max-width: $mq-sm) {
  .Modal-contentWrapper {
    width: calc(100vw - var(--gutter) * 2);
  }
}
