.Button {
  @include font-small;
  position: relative;
  padding: 1rem 2rem;
  background-color: var(--color-yellow);
  color: var(--color-contrast);
  border-radius: 0.25rem;
  font-weight: 700;
  white-space: nowrap;
  color: white;

  span {
    pointer-events: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.Button--blue {
  background-color: var(--color-blue);
}

.Button--start {
  width: 12rem;
  height: 12rem;
  padding: 0;
  margin: var(--gutter) 0;
  z-index: 1;
  background-color: transparent;
  min-height: 10rem;
  max-height: 24vh;
  img {
    min-height: 10rem;
    max-height: 24vh;
  }
}

.Button--upload {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - calc(var(--gutter) * 2));
  height: calc(100% - calc(var(--gutter) * 2));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  background-color: var(--color-light-gray);
  color: var(--color-gray);
  border-radius: 0;
}

.Button--small {
  @include font-small;
  font-size: 1rem;
  position: relative;
  padding: 0.55rem 0.75rem !important;
  padding-bottom: 0.35rem !important;
  line-height: 1.6em;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  p {
    font-size: var(--font-size-extra-small);
    position: absolute;
    bottom: -1.6rem;
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-mid-gray);
  }
}

.Button--red {
  background-color: var(--color-error);
  color: white;
}

.Button--neutral {
  background-color: rgba(200, 200, 200, 0.5);
}

.Button--progress {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transform: translateX(-50%);
  z-index: 99999999999;
  background-color: transparent;
  color: var(--color-base);
  font-weight: 400;

  &.--temp {
    bottom: var(--gutter-large);
    background-color: var(--color-yellow);
    color: var(--color-contrast);
  }
}

.Button-tempIcon {
  display: flex;
  width: 4rem;
  align-items: center;
  justify-content: center;
  height: 4rem;
  background-color: var(--color-blue);
  border-radius: 50%;

  &.disabled {
    background-color: var(--color-gray);
  }
}

.Button--home {
  position: fixed;
  bottom: var(--gutter-huge);
  left: 50%;
  transform: translateX(-50%);
}

.Button-bigArrowWrapper {
  display: flex;
  gap: var(--gutter);
  height: 7.4rem;

  &.--absolute {
    position: absolute;
    right: var(--gutter);
    bottom: var(--gutter);
  }

  p {
    color: white;
    margin: auto 0;
    font-weight: 400;
    padding-bottom: 0.1rem;

    &.--yellowText {
      color: var(--color-yellow);
    }
  }
  .Button-bigArrow {
    width: 10rem;
    height: auto;
    background-image: url("../img/arrow-big.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &.--turned {
      transform: rotateZ(180deg);
    }
  }
}

.Button-bigArrowWrapper--yellowText {
  p {
    color: var(--color-yellow);
  }
}

.Button-arrowBackWrapper {
  position: absolute;
  left: var(--gutter);
  bottom: var(--gutter);
  z-index: 999;
}

@media screen and (max-width: $mq-sm) {
  .Button {
    font-size: var(--font-size-extra-small);
    padding: 0.5rem;
  }

  .Button-bigArrowWrapper {
    height: 6rem;
    p {
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
      white-space: normal;
      text-align: right;
    }

    .Button-bigArrow {
      width: 7rem;
    }
  }
}
