.Image-Cropper {
  margin: 0 auto auto auto;
  height: 72vh;
  width: 108vh;

  .crop-container {
    position: relative;
    height: 100%;
  }

  &.--is-portrait {
    width: 40.5vh;
  }
}

.reactEasyCrop_Container {
  //top: -20rem !important;
}

.reactEasyCrop_Image {
  object-fit: contain;
}

.reactEasyCrop_CropArea {
  color: rgba($color: #fff, $alpha: 0.5) !important;
}
