.Navigation {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999999;
  color: var(--color-base);
  background-color: white;
  padding: 0.1rem var(--gutter);
  height: var(--nav-height);
  transition: transform 0.2s;
  height: 6.5rem;

  body.--nav-is-hidden & {
    transform: translateY(100%);
    pointer-events: none;
    opacity: 0;
  }
}

.Navigation-logoWrapper {
  width: auto;
  height: 100%;
  .Navigation-logo {
    width: 4.263rem;
    height: 5rem;
    aspect-ratio: 133/156;
  }
}

.Navigation-linksWrapper {
  display: flex;
  align-items: center;
  gap: var(--gutter);
  white-space: pre;
}

.Navigation-hamburgerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 2rem;
  width: 2.5rem;
}

.Navigation-hamburgerBar {
  width: 100%;
  height: 4px;
  background-color: var(--color-yellow);
  transition: 0.15s all;

  &.--top {
    body.--menu-is-open & {
      transform: translateY(0.875rem) rotateZ(45deg);
      transform-origin: 50%;
    }
  }

  &.--middle {
    body.--menu-is-open & {
      transform: scaleX(0);
    }
  }

  &.--bottom {
    body.--menu-is-open & {
      transform: translateY(-0.875rem) rotateZ(-45deg);
      transform-origin: 50%;
    }
  }
}

.Navigation-link {
  display: flex;
  flex-flow: column;
  padding: calc(var(--gutter) / 3);
  gap: 0.5rem;
  white-space: nowrap;
  color: var(--color-base);
  font-size: var(--font-size-extra-small);
  line-height: var(--line-height-small);
  color: var(--color-mid-gray);
  font-weight: 700;
  text-align: center;

  &.is-active {
    color: var(--color-contrast);
  }
}

.Navigation-link--generate {
  padding: 0;
}

.Navigation-progressWrap {
  position: fixed;
  z-index: 999999;
  right: var(--gutter);
  bottom: var(--gutter);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  gap: 0.5rem;
  border-radius: 50%;
  color: var(--color-contrast);
  text-align: center;
  white-space: nowrap;

  transition: transform 0.2s;

  body.--nav-is-hidden & {
    transform: translateY(100%) translateX(-50%);
    pointer-events: none;
    opacity: 0;
  }

  &.--left {
    left: var(--gutter);
    right: auto;
    transform: translateX(0);
  }
}

.Navigation-progressButton {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;

  span {
    font-size: var(--font-size-extra-small);
    color: var(--color-black);
  }
}

.Navigation-progressIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--gutter);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: var(--color-yellow);
  color: var(--color-base);

  &.green {
    background-color: var(--color-green);
  }
}
