// https://www.a11yproject.com/posts/how-to-hide-content/
.u-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.u-underline {
  text-decoration: underline;
}

.u-bold {
  font-weight: 700;
}

.u-hidden {
  display: none;
}

.u-hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.u-button {
  @include font-small;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-yellow);
  border-radius: 1000px;
  width: 8rem;
  height: 2.25rem;
  color: var(--color-base);
  padding-top: 0.25rem;

  &.--blured {
    background-color: rgba(180, 180, 180, 1);
  }

  @media screen and (max-width: $mq-lg) {
    font-weight: 400;
    padding-top: 0rem;
  }
}

.u-iconButtonWrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: var(--gutter);
  gap: var(--gutter);

  body.--is-explore-gallery & {
    display: none;
  }
}

.u-iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.4);
  border-radius: 1000px;
  width: 3rem;
  height: 3rem;
  padding: 0.65rem;
}
