@function colum-width($span: 1, $of: 12, $gap: var(--gutter)) {
  // Calculate percentage equivalent of column width within a grid layout
  // source: https://gist.github.com/AllThingsSmitty/05a067f3257286adaced
  @return calc(
    ((((100vw - (var(--gutter) * 2)) - ($gap * ($of - 1))) / $of) * $span) +
      ($gap * ($span - 1))
  );
}

@mixin font-h1 {
  color: var(--color-white);
  font-size: var(--font-size-very-large);
  line-height: var(--line-height-very-large);
  font-family: HelveticaNeue;
  font-weight: 700;
  letter-spacing: -0.05em;
}

@mixin font-h3 {
  color: var(--color-white);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  font-family: HelveticaNeue;
  font-weight: 400;
  letter-spacing: -0.033rem;
}

@mixin font-body {
  color: var(--color-contrast);
  font-size: var(--font-size-regular);
  line-height: var(--line-height-regular);
  font-family: HelveticaNeue;
  font-weight: 300;
  //letter-spacing: -0.05rem;
}

@mixin font-small {
  color: var(--color-contrast);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-family: HelveticaNeue;
  font-weight: 300;
  //letter-spacing: -0.05rem;
}

@mixin font-size-extra-small {
  color: var(--color-contrast);
  font-size: var(--font-size-extra-small);
  line-height: var(--line-height-extra-small);
  font-family: HelveticaNeue;
  font-weight: 300;
  //letter-spacing: -0.05rem;
}
