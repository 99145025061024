.Form {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  margin-bottom: var(--gutter);

  &.--is-state--submitting {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.Form-fieldWrap {
}

.Form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  display: block;
  white-space: nowrap;
}

.Form-field {
  padding: 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-contrast);
  width: 100%;
  max-width: 24rem;
  min-width: 18rem;
}

.Form-message {
  width: 100%;
  text-align: center;

  .Form.is-error & {
    color: red;
  }

  .Form.is-success & {
    color: var(--color-green);
  }
}

.Form-checkboxLabel {
  @include font-size-extra-small;
  position: fixed;
  left: 50%;
  bottom: 3.4rem;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 1rem;
  white-space: normal;
  cursor: pointer;
  background-color: var(--color-base);
  border: 2px solid var(--color-yellow);
  padding: 0.75rem 1.75rem;
  //border-radius: 1000rem;
  //box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.35);
  width: calc(100vw - var(--gutter) * 2);
  max-width: 40rem;
  span {
    flex: 1;
    text-align: left;
    color: var(--color-contrast);
    padding-top: 0.35rem;
    line-height: 1rem;
    button {
      text-decoration: underline;
    }
  }
}

.Form-checkbox {
  border-radius: 0.1rem;
  outline: 1px solid var(--color-contrast);
  position: relative;
  width: 2rem;
  height: 2rem;

  &:checked {
    border-width: 0;

    // checkmark
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      height: 80%;
      transform: translate(-50%, -50%);
      background-image: url("../img/checkmark.svg");
      background-size: 100%;
    }
  }
}

@media screen and (max-width: 1100px) {
  .Form-checkboxLabel {
    bottom: 7rem;
  }
}

@media screen and (max-width: 740px) {
  .Form-checkboxLabel {
    bottom: 10rem;
  }
}

@media screen and (max-width: $mq-sm) {
  .Form-checkboxLabel {
    bottom: 8rem;
  }
}
