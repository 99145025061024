.Block {
  display: flex;
  flex-flow: column;
  padding: calc(var(--gutter) * 1.5) var(--gutter);
  color: var(--color-base);

  @media screen and (min-width: $mq-lg) {
    padding: var(--gutter-large);
  }
}

.Block--yellow {
  background-color: var(--color-yellow);
  color: var(--color-contrast);
}

.Block--light-yellow {
  background-color: var(--color-light-yellow);
  color: var(--color-contrast);
}

.Block--blue {
  background-color: var(--color-blue);
  color: var(--color-contrast);
}

.Block--green {
  background-color: var(--color-green);
  color: var(--color-contrast);
}

.Block--centeredStart {
  position: fixed;
  top: calc(var(--inner-height) * 0.23);
  left: 50%;
  transform: translate(-50%);
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Block--image {
  max-height: 32rem;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: bottom;
  }

  @media screen and (max-width: $mq-md) {
    display: none;
  }
}

.Block-title {
  @include font-h1;
}

.Block-title--small {
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
}

.Block-text--start {
  font-size: 1.4rem;
  line-height: 1.55rem;
  margin-top: 0.25rem;
  text-align: center;
  font-weight: 400;
}

.Block-text--feed {
  font-size: 1.4rem;
  margin-top: 0.25rem;
  text-align: center;
  color: var(--color-contrast);

  &.--small {
    @include font-small;
  }
}

.Block-fileUpload {
  opacity: 0;
  position: absolute;
  inset: 0;
  cursor: pointer;
}

.Block-image {
  height: auto;
  max-height: calc(100vh - var(--nav-height));
  margin: var(--gutter);
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.Block-image--top {
  margin: 6.5rem var(--gutter) auto var(--gutter);
}

.Block-image--background {
  margin-bottom: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
}

.Block-image--placeholder {
  background-image: url("../../public/example-photos/example-1.jpg");
  width: calc(100vw - var(--gutter-large));
  height: calc(100vh - var(--gutter-large));
  margin: auto;
  transform: translateY(calc(var(--gutter-large) / 2));
  background-color: var(--color-footer);
}

.Block-image--reference {
  position: absolute;
  left: var(--gutter);
  top: var(--gutter);
  max-width: 25vw;
  min-width: 8rem;
  margin: 0;
  border-radius: 1rem;
  border: 2px solid var(--color-gray);

  @media screen and (min-width: $mq-lg) {
    max-width: 20vw;
  }
}

.Block-backgroundBlur {
  backdrop-filter: blur(30px);
  width: 100%;
  height: 100%;
}

.Block-generationButtonWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 50;
  gap: 0.5rem;
  width: 100%;

  button {
    display: flex;
    flex-flow: column;
    align-items: center;
    white-space: wrap;
  }
}
